import { useCallback, useEffect, useState } from "react";
import { Api } from "./api";
import { NewsData } from "./apiTypes";
import { ResponseCallback } from "./types";

export function useNews(props: {
  api: Api;
  callback: ResponseCallback;
}) {
  const { api, callback } = props;
  const [currentNews, setCurrentNews] = useState<NewsData | undefined>(undefined);

  const getNews = useCallback(async () => {
    const data = await api.newsGet();
    setCurrentNews(data as NewsData);
    callback('getNews: ' + JSON.stringify(data));
  }, [api, callback]);

  const setNews = useCallback(async (args: {
    news: string;
    password: string;
  }) => {
    try {
      const resp = await api.newsPost({
        password: args.password,
        news: {
          message: args.news,
          updated: new Date().getTime(),
        },
      });
      callback('setNews: ' + JSON.stringify(resp));
      await getNews();
    } catch (err) {
      callback((err as Error).toString());
    }
  }, [api, callback, getNews]);

  const setNewsModerator = useCallback(async (news: string) => {
    try {
      const resp = await api.newsPostModerator({
        news: {
          message: news,
          updated: new Date().getTime(),
        },
      });
      callback('setNewsModerator: ' + JSON.stringify(resp));
      await getNews();
    } catch (err) {
      callback((err as Error).toString());
    }
  }, [api, callback, getNews]);

  useEffect(() => {
    getNews();
  }, [getNews]);

  return {
    currentNews,
    getNews,
    setNews,
    setNewsModerator,
  };
}
