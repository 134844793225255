import React, { useCallback, useState } from 'react';
import { TextArea } from './UserSection';
import { AdminSectionProps } from './types';
import { FlexLeft } from './util';
import { FlagResponse } from './apiTypes';

export function RenderFlagRows(rows: undefined | FlagResponse[]) {
  return (
    <div style={{ width: '600px', }}>
      {rows ? (
        <div>
          {rows.length} results
          {rows.map(r => (
            <div key={r.email} style={{ border: '1px solid black', padding: '1em', }}>
              <div>aid: {r.aid}</div>
              <div>email: {r.email}</div>
              <div>tag: {r.tag}</div>
              <div>vipAt: {new Date(r.created).toLocaleString()}</div>
            </div>
          ))}
        </div>
        ) : (
          <div>haven't queried yet</div>
        )}
    </div>
  )
}

interface FlagSectionProps extends AdminSectionProps {
  flag: string;
  apiGet(body: { password: string }): Promise<FlagResponse[]>;
  apiSet(body: { password: string, emails: string[], setTo: boolean, }): Promise<FlagResponse[]>;
}

export function FlagSection(props: FlagSectionProps) {
  const { flag, apiGet, apiSet, password, addResponse } = props;
  const [getResult, setGetResult] = useState(undefined as undefined | FlagResponse[]);
  const [postResult, setPostResult] = useState('');
  const [emailTextArea, setEmails] = useState('');
  const [isFlag, setIsFlag] = useState(false);

  const getFlag = useCallback(async () => {
    try {
      const data = await apiGet({ password, });
      setGetResult(data);
      addResponse(`get${flag}: ${JSON.stringify(data)}`);
    } catch (err) {
      addResponse((err as Error).toString());
    }
  }, [flag, apiGet, password, addResponse, setGetResult]);
  const setFlag = useCallback(async () => {
    try {
      const emails = emailTextArea.split('\n').map(s => s.trim()).filter(s => s);
      const data = await apiSet({ password, emails, setTo: isFlag, });
      setPostResult(JSON.stringify(data, null, 2));
      addResponse(`set${flag}: ${JSON.stringify(data)}`);
    } catch (err) {
      addResponse((err as Error).toString());
    }
  }, [flag, apiSet, password, addResponse, emailTextArea, isFlag, setPostResult]);

  return (
    <section>
      <h2>{flag}</h2>
      <FlexLeft>
        <div>
          <FlexLeft>
            <div>
              <b>Get {flag}</b>
            </div>
            <div>
              <button onClick={getFlag}>SUBMIT</button>
            </div>
          </FlexLeft>
          <p>
            {RenderFlagRows(getResult)}
          </p>
        </div>
        <div>
          <FlexLeft>
            <div>
              <b>Set {flag}</b>
            </div>
            <div>
              <button onClick={setFlag}>SUBMIT</button>
            </div>
          </FlexLeft>
          <p>
            isFlag: {isFlag.toString()} <button onClick={() => setIsFlag(!isFlag)}>toggle</button>
          </p>
          <p>
            <TextArea value={emailTextArea} onChange={e => setEmails(e.target.value)} />
          </p>
          <p>
            <TextArea placeholder='readonly' value={postResult} readOnly={true} />
          </p>
        </div>
      </FlexLeft>
    </section>
  );
}
