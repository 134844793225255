import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { AccessType } from './types';
import { hash32 } from './util';

const LockScreenContainer = styled.div`
  width: 100%;
  height: 100%;

  font-family: monospace;
  font-size: 10em;
  color: black;
  background-color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  user-select: none;
`;

export function LockScreen(props: {
  onUnlock: (access: AccessType) => void,
}) {
  const { onUnlock } = props;
  let clicks = useRef(0);
  let timeout = useRef<NodeJS.Timeout>();
  const onClick = useCallback(() => {
    clicks.current++;
    if (hash32(clicks.current.toString()) === '330ca589') {
      timeout.current = setTimeout(() => onUnlock(AccessType.Admin), 3000);
    } else {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = undefined;
    }
  }, [onUnlock]);

  const [pass, setPass] = useState('');

  useEffect(() => {
    const unlockedAdmin = hash32(pass) === '4603a4f3';
    if (unlockedAdmin) {
      onUnlock(AccessType.Admin);
    }
    const unlockedModerator = hash32(pass) === 'c5aca61a';
    if (unlockedModerator) {
      onUnlock(AccessType.Moderator);
    }
  });
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      const char = e.key;
      if (char.trim().length === 0) {
        setPass('');
      } else {
        setPass(pass => pass + e.key);
      }
    };
    window.addEventListener('keydown', handler);
    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, []);

  return (
    <LockScreenContainer>
      <span onClick={onClick}>
        403
      </span>
    </LockScreenContainer>
  );
}
