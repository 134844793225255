import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { AdminSectionProps } from './types';
import { FlexLeft } from './util';
import { FlavorResponse } from './apiTypes';

export const TextArea = styled.textarea`
  width: 300px;
  height: 600px;
`;

export function RenderFlavorRows(rows: undefined | FlavorResponse[]) {
  return (
    <div style={{ width: '600px', }}>
      {rows ? (
        <div>
          {rows.length} results
          {rows.map(r => (
            <div key={r.email} style={{ border: '1px solid black', padding: '1em', }}>
              <div>aid: {r.aid}</div>
              <div>email: {r.email}</div>
              <div>tag: {r.tag}</div>
              <div>flavors: {r.flavors.join(', ')}</div>
            </div>
          ))}
        </div>
      ) : (
        <div>haven't queried yet</div>
      )}
    </div>
  )
}

export function FlavorSection(props: AdminSectionProps) {
  const { api, password, addResponse } = props;
  const [getResult, setGetResult] = useState(undefined as undefined | FlavorResponse[]);
  const [postResult, setPostResult] = useState('');
  const [emailTextArea, setEmailTextArea] = useState('');
  const [flavorTextArea, setFlavorTextArea] = useState('');

  const getFlavors = useCallback(async () => {
    try {
      const emails = emailTextArea.split('\n').map(s => s.trim()).filter(s => s);
      const data = await api.flavorsGet({ password, emails, });
      setGetResult(data);
      addResponse(`flavorsGet: ${JSON.stringify(data)}`);
    } catch (err) {
      addResponse((err as Error).toString());
    }
  }, [api, password, addResponse, emailTextArea, setGetResult]);
  const addFlavors = useCallback(async () => {
    try {
      const emails = emailTextArea.split('\n').map(s => s.trim()).filter(s => s);
      const flavors = flavorTextArea.split('\n').map(s => s.trim()).filter(s => s);
      const data = await api.flavorsAdd({ password, emails, flavors, });
      setPostResult(JSON.stringify(data, null, 2));
      addResponse(`flavorsAdd: ${JSON.stringify(data)}`);
    } catch (err) {
      addResponse((err as Error).toString());
    }
  }, [api, password, addResponse, emailTextArea, flavorTextArea, setPostResult]);
  const removeFlavors = useCallback(async () => {
    try {
      const emails = emailTextArea.split('\n').map(s => s.trim()).filter(s => s);
      const flavors = flavorTextArea.split('\n').map(s => s.trim()).filter(s => s);
      const data = await api.flavorsRemove({ password, emails, flavors, });
      setPostResult(JSON.stringify(data, null, 2));
      addResponse(`flavorsRemove: ${JSON.stringify(data)}`);
    } catch (err) {
      addResponse((err as Error).toString());
    }
  }, [api, password, addResponse, emailTextArea, flavorTextArea, setPostResult]);

  return (
    <section>
      <h2>Flavors</h2>
      <FlexLeft>
        <div>
          <FlexLeft>
            <div>
              <b>Get</b>
            </div>
            <div>
              <button onClick={getFlavors}>SUBMIT</button>
            </div>
          </FlexLeft>
          <p>
            {RenderFlavorRows(getResult)}
          </p>
        </div>
        <div>
          <FlexLeft>
            <div>
              <button onClick={addFlavors}>ADD</button>
            </div>
            <div>
              <button onClick={removeFlavors}>REMOVE</button>
            </div>
          </FlexLeft>
          <br />
          <FlexLeft>
            <div>
              <TextArea
                value={emailTextArea}
                onChange={e => setEmailTextArea(e.target.value)}
                placeholder="Line separate emails"
              />
            </div>
            <div>
              <TextArea
                value={flavorTextArea}
                onChange={e => setFlavorTextArea(e.target.value)}
                placeholder="Line separate flavors"
              />
            </div>
          </FlexLeft>
          <p>
            <TextArea placeholder='readonly' value={postResult} readOnly={true} />
          </p>
        </div>
      </FlexLeft>
    </section>
  );
}
