import React, { useEffect, useRef, useState } from 'react';
import { useNews } from './hookNews';
import { AdminSectionProps } from './types';
import { renderUndefined } from './util';

interface NewsCategories {
  label: string;
  links: string[];
}

const exampleNews: NewsCategories[] = [{
  label: 'Dev & Misc',
  links: [
    `Watch the dev work on the game tonight on <a href="https://twitch.tv/mpaulweeks" target="_blank">twitch</a> at 8pm EST`,
    `Streaming game dev now on <a href="https://twitch.tv/mpaulweeks" target="_blank">twitch</a>!`,
    `Watch the TLA documentary on <a href="https://www.youtube.com/watch?v=4p1uCwywgj4" target="_blank">YouTube</a>!`,
    `Watch the April Fools tournament on <a href="https://www.youtube.com/watch?v=UTPm1w5tHAY" target="_blank">YouTube</a>!`,
    `<a href="https://twitter.com/ToughLoveArena/status/1503413848925216771" target="_blank">BUY SOAP</a>`,
    `Check out this <a href="https://www.youtube.com/watch?v=j-q6lUe_zvU" target="_blank">combo video</a>!`,
  ]
}, {
  label: 'TLG',
  links: [
    `Sign up for <a href="todo" target="_blank">Tough Love Gauntlet</a>!`,
    `Sign up for <a href="https://challonge.com/ToughLoveGauntlet000" target="_blank">Tough Love Gauntlet</a>!`,
    `Watch the Tough Love Gauntlet tournament live on <a href="https://twitch.tv/shygybeats" target="_blank">twitch</a>!`,
    `Sign up for <a href="todo" target="_blank">Tough Love Gauntlet: Modded Edition</a>!`,
    `Watch the Tough Love Gauntlet MODDED tournament live on <a href="https://twitch.tv/shygybeats" target="_blank">twitch</a>!`,
    `Sign up for the <a href="todo" target="_blank">Mid Tier</a> tournament for beginners!`,
    `Watch the Mid Tier beginner tournament live on <a href="https://twitch.tv/shygybeats" target="_blank">twitch</a>!`,
  ],
}, {
  label: 'Survey & State of the Game',
  links: [
    `Please check out this month's <a href="todo" target="_blank">Player Survey</a> <3`,
    `Please check out the <a href="https://forms.gle/mVrwhL2ssivtA6MC6" target="_blank">August Player Survey</a> <3`,
    `Watch the State of the Game stream tonight on <a href="https://twitch.tv/mpaulweeks" target="_blank">twitch</a> at 8pm EST`,
    `State of the Game is live now on <a href="https://twitch.tv/mpaulweeks" target="_blank">twitch</a>!`,
    `Watch the latest State of the Game on <a href="https://www.youtube.com/watch?v=a97i_H434Zk" target="_blank">YouTube</a>!`,
  ],
}, {
  label: 'Tournaments',
  links: [
    `Sign up for the <a href="https://challonge.com/tlanormal" target="_blank">Completely Normal Tournament</a> on April 2nd`,
    `Sign up for the <a href="https://challonge.com/tlahalloween23" target="_blank">Halloween Tournament</a> 🎃`,
    `Sign up for the <a href="https://challonge.com/tla2year" target="_blank">Birthday Tournament</a> on January 6th`,
    `Sign up for the <a href="https://challonge.com/tlafools23" target="_blank">April Fools Tournament</a> on April 7th`,
    `Sign up for the <a href="https://challonge.com/tlasummer24" target="_blank">Summer Tournament</a> on July 12`,
    `Sign up for the <a href="https://challonge.com/tlabday24" target="_blank">Birthday Tournament</a> on January 5th`,
  ],
}];

const exampleMultiLine = [
`Paul is streaming dev work for Tough Love Arena live @ https://twitch.tv/mpaulweeks\n\nCome say hi! #indiedev`,
`Tough Love Gauntlet is happening this weekend! Don't forget to sign up:

https://challonge.com/ToughLoveGauntlet000

You can find more events like these on our about page: https://about.toughlovearena.com/events`,
`Tough Love Gauntlet is happening this weekend! Don't forget to sign up:

FRI: https://challonge.com/ToughLoveGauntlet000
SAT: https://challonge.com/TLGMOD000

You can find more events like these on our about page: https://about.toughlovearena.com/events`,
];

function MultiLineText(props: { msg: string, }) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const { current } = textAreaRef;
    if (current?.clientHeight) {
      // if (current.clientHeight < current.scrollHeight) {
      current.style.height = current.scrollHeight + "px";
      // }
    }
  }, []);

  return (
    <textarea
      style={{ width: '800px', }}
      ref={textAreaRef}
      readOnly={true}
      value={props.msg}
    />
  );
}

function TweetOption(props: {
  tweet: string;
}) {
  const params = new URLSearchParams();
  params.set('text', props.tweet);
  const url = `http://twitter.com/intent/tweet?${params.toString()}`;
  return (
    <div style={{margin: '0.5em 0'}}>
      <div>
        <a href={url} target="_blank" rel="noreferrer">link to Twitter with text</a>
      </div>
      <div>
        <MultiLineText msg={props.tweet} />
      </div>
    </div>
  )
}

function NewsOption(props: {
  html: string;
  onDraft: () => void;
  onSet: () => void;
}) {
  return (
    <span>
      <button onClick={props.onDraft}>draft</button>
      &nbsp;
      <button onClick={props.onSet}>set</button>
      &nbsp;
      {props.html}
    </span>
  )
}

export function NewsSection(props: AdminSectionProps) {
  const { currentNews, getNews, setNews } = useNews({
    api: props.api,
    callback: props.addResponse,
  });
  const [newsDraft, setNewsDraft] = useState('');

  const setNewsApi = (news: string) => {
    setNews({
      news,
      password: props.password,
    });
  };

  return (
    <section>
      <h2>News</h2>

      <div>
        get &nbsp;
        <button onClick={() => getNews()}>news</button>
      </div>

      <h3>current raw</h3>
      {currentNews?.message ? <div>{currentNews.message}</div> : renderUndefined()}

      <h3>current preview</h3>
      {currentNews?.message ? (
        <div dangerouslySetInnerHTML={{ __html: currentNews.message, }}></div>
      ) : renderUndefined()}

      <h3>draft preview</h3>
      {newsDraft ? (
        <div dangerouslySetInnerHTML={{ __html: newsDraft, }}></div>
      ) : renderUndefined()}

      <h3>draft news</h3>
      <div>
        <input style={{ width: '40em', }} onChange={e => setNewsDraft(e.target.value)} value={newsDraft} />
        <br />
        <button onClick={() => setNewsApi(newsDraft)}>submit news</button>
      </div>

      <h3>common news</h3>
      {exampleNews.map((category, ci) => (
        <div key={ci}>
          <div>{category.label}</div>
          <ul>
            {category.links.map((html, hi) => (
              <li key={hi}>
                <NewsOption
                  html={html}
                  onDraft={() => setNewsDraft(html)}
                  onSet={() => setNewsApi(html)}
                />
              </li>
            ))}
          </ul>
        </div>
      ))}

      <h3>common tweets</h3>
      {exampleMultiLine.map((text, ti) => (
        <div key={ti}>
          <TweetOption tweet={text} />
        </div>
      ))}

    </section>
  );
}
