import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { AccountInfo } from './apiTypes';
import { AdminSectionProps } from './types';
import { FlexLeft } from './util';

const Email = styled.input`
  width: 600px;
`;
export const TextArea = styled.textarea`
  width: 600px;
  height: 600px;
`;

export function UserSection(props: AdminSectionProps) {
  const { api, password, addResponse } = props;
  const [draftGetEmail, setDraftGetEmail] = useState('');
  const [resultGetBody, setResultGetEmail] = useState('');
  const [draftPostEmail, setDraftPostEmail] = useState('');
  const [draftPostBody, setDraftPostBody] = useState('');
  const [draftLeaderboardEmail, setDraftLeaderboardEmail] = useState('');

  const getUser = useCallback(async () => {
    try {
      const data = await api.userGet({ password, email: draftGetEmail });
      setResultGetEmail(JSON.stringify(data, null, 2));
      addResponse('getUser: ' + JSON.stringify(data));
    } catch (err) {
      addResponse((err as Error).toString());
    }
  }, [api, password, addResponse, draftGetEmail, setResultGetEmail]);
  const postUser = useCallback(async () => {
    try {
      const account: AccountInfo = JSON.parse(draftPostBody);
      const data = await api.userPost({ password, email: draftPostEmail, account, });
      addResponse('postUser: ' + JSON.stringify(data));
    } catch (err) {
      addResponse((err as Error).toString());
    }
  }, [api, password, addResponse, draftPostEmail, draftPostBody]);
  const leaderboardRefresh = useCallback(async () => {
    try {
      const data = await api.leaderboardRefresh({ password, email: draftLeaderboardEmail, });
      addResponse('leaderboardRefresh: ' + JSON.stringify(data));
    } catch (err) {
      addResponse((err as Error).toString());
    }
  }, [api, password, addResponse, draftLeaderboardEmail]);

  return (
    <section>
      <h2>User Edit</h2>
      <FlexLeft>
        <div>
          <FlexLeft>
            <div>
              <b>Get</b>
            </div>
            <div>
              <button onClick={getUser}>SUBMIT</button>
            </div>
          </FlexLeft>
          <p>
            <Email placeholder='email' value={draftGetEmail} onChange={e => setDraftGetEmail(e.target.value.trim())} />
          </p>
          <p>
            <TextArea placeholder='readonly' value={resultGetBody} readOnly={true} />
          </p>
        </div>
        <div>
          <FlexLeft>
            <div>
              <b>Post</b>
            </div>
            <div>
              <button onClick={postUser}>SUBMIT</button>
            </div>
          </FlexLeft>
          <p>
            <Email placeholder='email' value={draftPostEmail} onChange={e => setDraftPostEmail(e.target.value.trim())} />
          </p>
          <p>
            <TextArea value={draftPostBody} onChange={e => setDraftPostBody(e.target.value)} />
          </p>
        </div>
        <div>
          <FlexLeft>
            <div>
              <b>Leaderboard Refresh</b>
            </div>
            <div>
              <button onClick={leaderboardRefresh}>SUBMIT</button>
            </div>
          </FlexLeft>
          <p>
            <Email placeholder='email' value={draftLeaderboardEmail} onChange={e => setDraftLeaderboardEmail(e.target.value.trim())} />
          </p>
        </div>
      </FlexLeft>
    </section>
  );
}
