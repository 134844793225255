import { useRef } from "react";
import styled from "styled-components";
import { Api } from "./api";
import { useNews } from "./hookNews";
import { useEventsTLA } from "./hookTLG";
import { ResponseCallback } from "./types";

const Container = styled.div`
  font-size: 1.5rem;
  margin: 1em;

  & header {
    font-weight: bold;
    font-size: 1.2em;
    margin: 1em 0;
  }

  & button {
    cursor: pointer;
    font-size: 1em;
    padding: 0 0.5em;
    margin: 0.1em 0;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const logOut: ResponseCallback = msg => console.log('news', msg);

export function Moderator() {
  const apiProd = useRef(new Api());

  const { currentNews, setNewsModerator } = useNews({
    api: apiProd.current,
    callback: logOut,
  });

  const { tlg } = useEventsTLA();

  const buttons = [{
    event: undefined,
    html: `Please check out the <a href="https://forms.gle/mVrwhL2ssivtA6MC6" target="_blank">August Player Survey</a> <3`,
  }, {
    event: undefined,
    html: `Watch the Tough Love Gauntlet tournament live on <a href="https://twitch.tv/shygybeats" target="_blank">twitch</a>!`,
  }, ...tlg.map(ev => ({
    event: ev,
    html: `Sign up for <a href="https://challonge.com/${ev.challonge}" target="_blank">${ev.title}</a>!`,
  }))];

  return (
    <Container>
      <h1>Moderator Tools</h1>
      <header>
        Current News
      </header>
      <div>
        {currentNews?.message ? (
          <span dangerouslySetInnerHTML={{ __html: currentNews.message }}></span>
        ) : (
          <i>[no news set]</i>
        )}

      </div>
      <header>
        Update News
      </header>
      <div>
        <button onClick={() => setNewsModerator('')}>
          clear news
        </button>
      </div>
      {buttons.map((b, bi) => (
        <ButtonRow key={bi}>
          <button onClick={() => setNewsModerator(b.html)}>
            set
          </button>
          <div
            style={{
              border: '1px solid black',
              padding: '3px 6px',
            }}
            dangerouslySetInnerHTML={{ __html: b.html }}>
          </div>
          <div><i>{b.event?.date}</i></div>
        </ButtonRow>
      ))}
    </Container>
  )
}
