import { AccountInfo, FeatureFlagResponse, FlagResponse, FlavorResponse, NewsData } from './apiTypes';

export class Api {
  private readonly url: string;
  readonly env: string;
  constructor(useLocal?: boolean) {
    this.env = useLocal ? 'Local' : 'Prod';
    this.url = useLocal
      ? 'http://localhost:8080/'
      : 'https://us-central1-fighter-api.cloudfunctions.net/webApi/api/v1/';
  }

  private async get<T>(path: string): Promise<T> {
    const resp = await fetch(this.url + path);
    if (resp.ok) {
      const json = await resp.json();
      console.log(resp.status, path, json);
      return json as T;
    } else {
      const text = await resp.text();
      throw new Error(`${resp.status} (${path}) ${text}`);
    }
  };
  private async post<T>(path: string, reqBody: object): Promise<T> {
    console.log('???', path, reqBody);
    const resp = await fetch(this.url + path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reqBody),
    });
    if (resp.ok) {
      const json = await resp.json();
      console.log(resp.status, path, json);
      return json as T;
    } else {
      const text = await resp.text();
      throw new Error(`${resp.status} (${path}) ${text}`);
    }
  };

  async newsGet() {
    try {
      return await this.get<NewsData>('news');
    } catch (err) {
      return Promise.resolve(undefined);
    }
  }
  async newsPost(reqBody: { password: string, news: NewsData, }) {
    return this.post<NewsData>('admin/news', reqBody);
  }
  // checks message to see if valid format
  async newsPostModerator(reqBody: { news: NewsData, }) {
    return this.post<NewsData>('mod/news', reqBody);
  }

  async flagsGet() {
    try {
      return await this.get<FeatureFlagResponse>('flags');
    } catch (err) {
      return Promise.resolve(undefined);
    }
  }
  async flagsPost(reqBody: { password: string, flags: FeatureFlagResponse, }) {
    return this.post<FeatureFlagResponse>('admin/flags', reqBody);
  }

  async userGet(reqBody: { password: string, email: string }) {
    return this.post<AccountInfo>('admin/user/get', reqBody);
  }
  async userPost(reqBody: { password: string, email: string, account: AccountInfo }) {
    return this.post<AccountInfo>('admin/user/post', reqBody);
  }

  async leaderboardRefresh(reqBody: { password: string, email: string }) {
    return this.post<AccountInfo>('admin/user/leaderboard/refresh', reqBody);
  }

  async getPaid(reqBody: { password: string }) {
    return this.post<FlagResponse[]>('admin/paid/all', reqBody);
  }
  async setPaid(reqBody: { password: string, emails: string[], setTo: boolean }) {
    return this.post<FlagResponse[]>('admin/paid/set', reqBody);
  }

  async getVip(reqBody: { password: string }) {
    return this.post<FlagResponse[]>('admin/vip/all', reqBody);
  }
  async setVip(reqBody: { password: string, emails: string[], setTo: boolean }) {
    return this.post<FlagResponse[]>('admin/vip/set', reqBody);
  }

  async flavorsGet(reqBody: { password: string, emails: string[] }) {
    return this.post<FlavorResponse[]>('admin/flavor/get', reqBody);
  }
  async flavorsAdd(reqBody: { password: string, emails: string[], flavors: string[] }) {
    return this.post<FlavorResponse[]>('admin/flavor/add', reqBody);
  }
  async flavorsRemove(reqBody: { password: string, emails: string[], flavors: string[] }) {
    return this.post<FlavorResponse[]>('admin/flavor/remove', reqBody);
  }
}
