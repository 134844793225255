import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Api } from './api';
import { FlagSection } from './FlagSection';
import { FlagsSection as FeatureFlagsSection } from './FeatureFlagsSection';
import { FlavorSection } from './FlavorSection';
import { NewsSection } from './NewsSection';
import { AdminSectionProps } from './types';
import { UserSection } from './UserSection';
import { FlexLeft, isLocalhost, renderUndefined } from './util';

const RootContainer = styled.div`
  font-size: 16px;

  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 100%;

  padding: 2em;
  box-sizing: border-box;

  color: black;
  background-color: rgba(255, 255, 255, 0.8);

  h1 {
    margin: 0;
  }
  h2 {
    text-decoration: underline;
  }
  h3 {
    text-decoration: none;
  }
  section {
    border-top: 1px solid black;
    margin: 1em 0;
  }
`;

type SectionType = 'news' | 'flags' | 'user' | 'paid' | 'vip' | 'flavor';
const Section: { [key: string]: SectionType } = {
  News: 'news',
  Flags: 'flags',
  User: 'user',
  Paid: 'paid',
  Vip: 'vip',
  Flavor: 'flavor',
};
const Sections: SectionType[] = [
  Section.News,
  Section.Flags,
  Section.User,
  Section.Paid,
  Section.Vip,
  Section.Flavor,
];

export function Admin() {
  const apiProd = new Api();
  const apiLocal = new Api(true);
  const respLimit = 10;
  const defaultResp = Array.from(Array(respLimit).keys()).map(i => '');

  const [api, setApi] = useState(isLocalhost ? apiLocal : apiProd);
  const [password, setPassword] = useState('');
  const [response, setResponse] = useState(defaultResp);
  const [section, setSection] = useState(Section.News);

  const addResponse = useCallback((message: string) => {
    const now = new Date().toLocaleTimeString();
    const newResp = `${api.env[0]} ${now} | ${message}`;
    setResponse(resp => [newResp].concat(...resp).slice(0, 10));
  }, [api, setResponse]);

  const childProps: AdminSectionProps = {
    password,
    api,
    addResponse,
  };

  return (
    <RootContainer>
      <FlexLeft>
        <div>
          <h2>Password</h2>
          <p>
            <input type='password' onChange={e => setPassword(e.target.value)} />
          </p>
        </div>

        <div>
          <h2>Api Env: {api.env}</h2>
          <p>
            <button onClick={() => setApi(apiLocal)}>Set Local</button>
            <button onClick={() => setApi(apiProd)}>Set Prod</button>
          </p>
        </div>

        <div>
          <h2>View: {section}</h2>
          <p>
            {Sections.map(s => (
              <button key={s} onClick={() => setSection(s)}>{s}</button>
            ))}
          </p>
        </div>
      </FlexLeft>

      <h2>Resources</h2>
      <p>
        <div>
          &gt; <a target="_blank" rel="noreferrer" href="https://data.toughlovearena.cloud/trailer.mp4">trailer.mp4</a>
        </div>
        <div>
          &gt; <a target="_blank" rel="noreferrer" href="https://docs.google.com/presentation/d/1GBqvm72F7a-ZLBf2SGRRQNyAigrsbHMk_ElG7rStOq4/edit#slide=id.p">EVO Slides</a>
        </div>
      </p>

      <h2>Response</h2>
      <p>
        {response.map((resp, i) => (
          <div key={i} style={{ fontFamily: 'monospace', whiteSpace: 'nowrap', overflow: 'hidden', }}>
            {resp || renderUndefined()}
          </div>
        ))}
      </p>

      {section === Section.News && <NewsSection {...childProps} />}
      {section === Section.Flags && <FeatureFlagsSection {...childProps} />}
      {section === Section.User && <UserSection {...childProps} />}
      {section === Section.Paid && (
        <FlagSection
          {...childProps}
          flag='Paid'
          apiGet={body => api.getPaid(body)}
          apiSet={body => api.setPaid(body)}
        />
      )}
      {section === Section.Vip && (
        <FlagSection
          {...childProps}
          flag='VIP'
          apiGet={body => api.getVip(body)}
          apiSet={body => api.setVip(body)}
        />
      )}
      {section === Section.Flavor && <FlavorSection {...childProps} />}
    </RootContainer>
  );
}
