import { Api } from "./api";

export type ResponseCallback = (resp: string) => void;

export interface AdminSectionProps {
  password: string;
  api: Api;
  addResponse: ResponseCallback;
}

export enum AccessType {
  Locked = 1,
  Admin,
  Moderator,
}
