import React, { useState } from 'react';
import { Admin } from './Admin';
import { LockScreen } from './LockScreen';
import { Moderator } from './Moderator';
import { AccessType } from './types';
import { isLocalhost } from './util';

const devUnlockAdmin = window.location.search.includes('unlock');
const devUnlockModerator = window.location.search.includes('moderator');
const defaultAccess = (
  (isLocalhost && devUnlockAdmin && AccessType.Admin) ||
  (isLocalhost && devUnlockModerator && AccessType.Moderator) ||
  AccessType.Locked
);

function App() {
  const [access, setAccess] = useState(defaultAccess);

  if (access === AccessType.Locked) {
    return <LockScreen onUnlock={access => setAccess(access)} />;
  }
  if (access === AccessType.Moderator) {
    return <Moderator />
  }
  if (access === AccessType.Admin) {
    return <Admin />;
  }

  throw new Error(`unsupported access type: ${access}`);
}

export default App;
