import { useEffect, useState } from "react";
import YAML from 'yaml';

// subset of actual types, just what we need
// https://github.com/toughlovearena/about.toughlovearena.com/blob/main/interfaces/events.ts
type EventWhenDTO = {
  date: string;
}
type EventDTO = {
  title: string;
  description: string;
  challonge?: string;
  when: EventWhenDTO[];
  series?: Partial<EventDTO>[];
}
type EventsYaml = {
  events: EventDTO[];
}
type EventLink = {
  title: string;
  challonge: string;
  date: string;
}

function unpackEvents(data: EventDTO[]): EventDTO[] {
  return data.map<EventDTO[]>(dto => {
    return dto.when.map<EventDTO[]>(when => {
      const thisEvent: EventDTO = {
        ...dto,
        when: [when],
      };
      const series = dto.series?.map<EventDTO>(newEvent => ({
        ...dto,
        series: [],
        ...newEvent,
        when: newEvent.when
          ? newEvent.when.map(newWhen => ({
            ...when,
            ...newWhen,
          }))
          : [when],
      })) ?? [];
      return [thisEvent, ...series];
    }).flat();
  }).flat();
}

export function useEventsTLA() {
  const [events, setEvents] = useState<EventDTO[]>();

  useEffect(() => {
    (async() => {
      const eventsResp = await fetch('https://about.toughlovearena.com/data/events.yaml');
      const eventsText = await eventsResp.text();
      const eventsYaml: EventsYaml = YAML.parse(eventsText);
      setEvents(eventsYaml.events);
    })();
  }, []);

  const tlg = unpackEvents(events ?? [])
    .filter(ev => ev.challonge?.startsWith('ToughLoveGauntlet'))
    .sort((a,b) => a.when[0].date > b.when[0].date ? 1 : -1)
    .reverse()
    .slice(0, 20)
    .reverse()
    .map<EventLink>(ev => ({
      title: ev.title,
      challonge: ev.challonge!,
      date: ev.when[0]!.date,
    }));

  return {
    events,
    tlg,
  };
}
