import React, { useCallback, useEffect, useState } from 'react';
import { FeatureFlagResponse } from './apiTypes';
import { AdminSectionProps } from './types';
import { TextArea } from './UserSection';
import { renderUndefined } from './util';

export function FlagsSection(props: AdminSectionProps) {
  const { api, password, addResponse } = props;
  const [currentFlags, setCurrentFlags] = useState<FeatureFlagResponse | undefined>(undefined);
  const [flagsTextArea, setFlagsTextArea] = useState('');

  const getFlags = useCallback(async () => {
    const data = await api.flagsGet();
    setCurrentFlags(data as FeatureFlagResponse);
    addResponse('getFlags: ' + JSON.stringify(data));
  }, [api, addResponse]);
  const setFlags = async () => {
    try {
      const flags = flagsTextArea.split('\n').map(s => s.trim()).filter(s => s);
      const resp = await api.flagsPost({
        password: password,
        flags: {
          flags,
          updated: new Date().getTime(),
        },
      });
      addResponse('setFlags: ' + JSON.stringify(resp));
      await getFlags();
    } catch (err) {
      addResponse((err as Error).toString());
    }
  };

  useEffect(() => {
    getFlags();
  }, [getFlags]);

  return (
    <section>
      <h2>Feature Flags</h2>
      <button onClick={() => getFlags()}>get flags</button>

      <h3>current flags</h3>
      {currentFlags && currentFlags.flags.length > 0 ? (
        <div>
          {currentFlags.flags.map(f => (
            <div>
              <code>{f}</code>
            </div>
          ))}
        </div>
       ) : renderUndefined()}

      <h3>update flags</h3>
      <div>
        <button onClick={setFlags}>submit flags</button>
      </div>
      <br />
      <div>
        <TextArea
          value={flagsTextArea}
          onChange={e => setFlagsTextArea(e.target.value)}
          placeholder="Line separate flags"
        />
      </div>

    </section>
  );
}
