import React from 'react';
import styled from 'styled-components';

export const isLocalhost = window.location.href.startsWith('http://localhost:');

export function renderUndefined() {
  return <div style={{ fontFamily: 'monospace', }}>[ undefined ]</div>;
}

// https://stackoverflow.com/a/22429679
export function hash32(str: string, seed?: number): string {
  /*jshint bitwise:false */
  let hval = (seed === undefined) ? 0x811c9dc5 : seed;

  for (let i = 0, l = str.length; i < l; i++) {
    hval ^= str.charCodeAt(i);
    hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
  }
  // Convert to 8 digit hex string
  return ("0000000" + (hval >>> 0).toString(16)).substr(-8);
}

export const FlexLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  & > div {
    padding: 0 2em;
    border-left: 1px solid black;
  }
  & > div:first-child {
    padding-left: 0;
    border: none;
  }
`;
